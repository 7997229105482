import { Banner, Box, GridContainer, useDuploTheme } from '@carnegie/duplo'
import { RouteLoadingIndicator } from '@common/components/RouteLoadingIndicator'
import { ScrollToTop } from '@common/components/ScrollToTop'
import { useSystemMessage } from '@common/components/systemMessages'
import { featureOverridesActive } from '@common/hooks/useFeatures'
import { useAuth } from '@common/stores/store'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { AppErrorBoundary } from './AppErrorBoundary'
import { Drawers } from './Drawers'
import { OfferingsDrawer } from './drawers/corporateActionsDrawer/OfferingsDrawer'
import { LogoutPage } from './pages/logout/Logout'
import { AppShell } from './pages/shell/AppShell'
import { Footer } from './pages/shell/Footer'
import { TopNavBar } from './pages/shell/Header'
import { MainMenu } from './pages/shell/Menu'

const ErrorPage = React.lazy(() => import('./pages/error/ErrorPage'))
const MarketPage = React.lazy(() => import('./pages/market/MarketPage'))
const ProfilePage = React.lazy(() => import('./pages/profile/ProfilePage'))
const ExplorePage = React.lazy(() => import('./pages/explore/ExplorePage'))
const ExploreResearchPage = React.lazy(() => import('./pages/research/researchExplore/ExploreResearchPage'))
const OverviewHomePage = React.lazy(() => import('./pages/overview/home/OverviewHomePage'))
const OverviewPage = React.lazy(() => import('./pages/overview/OverviewPage'))
const AccountPage = React.lazy(() => import('./pages/accounts/AccountPage'))
const TestPage = React.lazy(() => import('./pages/test/TestPage'))
const SearchPage = React.lazy(() => import('./pages/search/SearchPage'))
const ResearchAndCommentsPage = React.lazy(() => import('./pages/research/researchAndComments/ResearchAndCommentsPage'))
const MessageConfirmPage = React.lazy(() => import('./pages/messages/MessageConfirmPage'))
const MessageWritePage = React.lazy(() => import('./pages/messages/MessageWritePage'))
const ThreadReadPage = React.lazy(() => import('./pages/messages/ThreadReadPage'))
const MessagesStartPage = React.lazy(() => import('./pages/messages/MessagesStartPage'))
const SmallCaps = React.lazy(() => import('./pages/research/researchExplore/SmallCaps'))
const ShortTermPage = React.lazy(() => import('./pages/subscriptions/ShortTermPage'))
const MorningNewsPage = React.lazy(() => import('./pages/subscriptions/MorningNewsPage'))
const MacroInFocusPage = React.lazy(() => import('./pages/subscriptions/MacroInFocusPage'))
const OfferingsPage = React.lazy(() => import('./pages/offerings/OfferingsPage'))

// Drawers
const InstrumentDrawer = React.lazy(() => import('./drawers/instrumentDrawer/InstrumentDrawer'))
const NewsDrawer = React.lazy(() => import('./drawers/newsDrawer/NewsDrawer'))
const OrderDrawer = React.lazy(() => import('./drawers/orderDrawer/OrderDrawer'))
const FundOrderDrawer = React.lazy(() => import('./drawers/fundOrderDrawer/FundOrderDrawer'))
const ArticleDrawer = React.lazy(() => import('./drawers/articleDrawer/ArticleDrawer'))
const SeminarDrawer = React.lazy(() => import('./drawers/seminarDrawer/SeminarDrawer'))
const ResearchCommentsDrawer = React.lazy(() => import('./drawers/researchCommentsDrawer/ResearchCommentsDrawer'))
const ResearchDrawer = React.lazy(() => import('./drawers/researchDrawer/ResearchDrawer'))
const TransfersDrawer = React.lazy(() => import('./drawers/transfersDrawer/TransfersDrawer'))
const PodcastDrawer = React.lazy(() => import('./drawers/podcastDrawer/PodcastDrawer'))
const SubscriptionDrawer = React.lazy(() => import('./drawers/subscriptionDrawer/SubscriptionDrawer'))

const MenuWeb = () => {
  return (
    <MenuWebContainer>
      <MainMenu />
    </MenuWebContainer>
  )
}
MenuWeb.displayName = 'MenuWeb'

const MenuWebContainer = (props: React.HTMLAttributes<HTMLElement>) => {
  return (
    <aside css={{ width: 96, minWidth: 96, marginRight: 24 }} {...props}>
      {props.children}
    </aside>
  )
}

const AppRoutes = observer(() => {
  const duploTheme = useDuploTheme()
  const auth = useAuth()
  const { messages = [], onClose } = useSystemMessage()
  const message = messages?.[0]

  return (
    <Box color="text-default">
      {message?.id && (
        <Banner
          key={message.id}
          severity={message.severity}
          title={message.subject}
          description={message.message}
          onClose={() => onClose(message.id)}
        />
      )}
      <AppShell
        topNavBarStyle={{
          background: featureOverridesActive() ? duploTheme.colors.shiraz : undefined,
        }}
        sideBar={<MenuWeb />}
        topNavBar={<TopNavBar />}
        footer={<Footer />}
      >
        <AppErrorBoundary>
          <GridContainer
            justify="flex-start"
            alignContent="flex-start"
            style={{
              // Important or width can get pushed by too long content etc
              minWidth: 0,
            }}
          >
            <ScrollToTop />
            <Switch>
              <Route exact path="/">
                <Redirect to="/overview/home" />
              </Route>
              <Route exact path="/overview">
                <Redirect to="/overview/home" />
              </Route>
              {auth.hasLimitedView && (
                <Route exact path="/overview/home" component={() => <Redirect to="/overview/accounts" />} />
              )}
              <Route exact path="/market">
                <Redirect to="/market/overview" />
              </Route>
              <Route exact path={['/instrument/*', '/news/*', '/transfers/*']}>
                <RouteLoadingIndicator>
                  <OverviewHomePage />
                </RouteLoadingIndicator>
              </Route>
              <Route
                path="/market/"
                render={() => (
                  <RouteLoadingIndicator>
                    <MarketPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/messages/confirm"
                render={() => (
                  <RouteLoadingIndicator>
                    <MessageConfirmPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/messages/write/:partyId?"
                render={() => (
                  <RouteLoadingIndicator skeletonBackLink>
                    <MessageWritePage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/messages/thread/:id"
                render={() => (
                  <RouteLoadingIndicator skeletonBackLink>
                    <ThreadReadPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/messages/message/:id"
                render={() => (
                  <RouteLoadingIndicator>
                    <ThreadReadPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path={['/messages/sent/:page?', '/messages/:page?']}
                render={() => (
                  <RouteLoadingIndicator>
                    <MessagesStartPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path={['/profile/:tab/:subtab?/:id?/:page?', '/profile']}
                render={() => (
                  <RouteLoadingIndicator>
                    <ProfilePage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/explore/research"
                render={() => (
                  <RouteLoadingIndicator>
                    <ExploreResearchPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/explore"
                render={() => (
                  <RouteLoadingIndicator>
                    <ExplorePage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/accounts/:accountId?"
                render={() => (
                  <RouteLoadingIndicator>
                    <AccountPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/overview"
                render={() => (
                  <RouteLoadingIndicator>
                    <OverviewPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path={['/search/:query', '/search']}
                render={() => (
                  <RouteLoadingIndicator skeletonBackLink>
                    <SearchPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route path="/page-error" component={ErrorPage} />
              <Route
                path="/test"
                render={() => (
                  <RouteLoadingIndicator>
                    <TestPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/research/research-and-comments/:page?"
                render={() => (
                  <RouteLoadingIndicator skeletonBackLink>
                    <ResearchAndCommentsPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/research/small-caps"
                render={() => (
                  <RouteLoadingIndicator skeletonBackLink>
                    <SmallCaps />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/subscriptions/short-term/:page?"
                render={() => (
                  <RouteLoadingIndicator skeletonBackLink>
                    <ShortTermPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/subscriptions/morning-news/:page?"
                render={() => (
                  <RouteLoadingIndicator skeletonBackLink>
                    <MorningNewsPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/subscriptions/macro-in-focus/:page?"
                render={() => (
                  <RouteLoadingIndicator skeletonBackLink>
                    <MacroInFocusPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/offerings"
                render={() => (
                  <RouteLoadingIndicator skeletonBackLink>
                    <OfferingsPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route
                path="/logout"
                exact
                render={() => (
                  <RouteLoadingIndicator>
                    <LogoutPage />
                  </RouteLoadingIndicator>
                )}
              />
              <Route path="*">
                <Redirect to="/overview/home" />
              </Route>
            </Switch>
          </GridContainer>
        </AppErrorBoundary>
      </AppShell>
      {/* Drawers */}
      <Drawers
        renderDrawer={(drawerType) => {
          if (drawerType === 'instrument') return <InstrumentDrawer />
          if (drawerType === 'news') return <NewsDrawer />
          if (drawerType === 'order') return <OrderDrawer />
          if (drawerType === 'fundorder') return <FundOrderDrawer />
          if (drawerType === 'article') return <ArticleDrawer />
          if (drawerType === 'seminar') return <SeminarDrawer />
          if (drawerType === 'podcast') return <PodcastDrawer />
          if (drawerType === 'research_comments') return <ResearchCommentsDrawer />
          if (drawerType === 'research') return <ResearchDrawer />
          if (drawerType === 'transfers') return <TransfersDrawer />
          if (drawerType === 'subscription') return <SubscriptionDrawer />
          if (drawerType === 'offering') return <OfferingsDrawer />

          // Will throw an error
          return undefined
        }}
      />
    </Box>
  )
})
AppRoutes.displayName = 'AppRoutes'

export default AppRoutes
