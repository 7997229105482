// i18n.js
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import enTranslations from './locales/en/translation.json'
import svTranslations from './locales/sv/translation.json'

i18n
  .use(initReactI18next)
  .use(XHR)
  .use(LanguageDetector)
  .init({
    lng: localStorage.getItem('i18nextLng') || 'sv',

    resources: {
      sv: {
        translation: svTranslations,
      },
      en: {
        translation: enTranslations,
      },
    },
    // preload: ['sv', 'en'],
    fallbackLng: 'sv',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    // backend: {
    //   loadPath: '/locales/{{lng}}/{{ns}}.json',
    //   jsonIndent: 2,
    // },
    nsSeparator: ':::',
    keySeparator: '::',

    // Just uncomment if you want to start extracting translations (without a backend)
    // saveMissing: true,
    // missingKeyHandler: (lng, ns, key, fallbackValue) => {
    //   missingTranslations[key] = fallbackValue

    //   console.log(
    //     'Updated missing translations:',
    //     missingTranslations,
    //     JSON.stringify(missingTranslations, undefined, 2)
    //   )
    // },
  })
export default i18n
