import delay from 'delay'
import { action, computed, makeObservable, observable } from 'mobx'
import { nanoid } from 'nanoid'
import { createContext, useContext } from 'react'
import { matchPath } from 'react-router'
import urlJoin from 'url-join'
import { NavigationStore } from '../../stores/navigationStore'

export type DrawerType = string

class Drawer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: Record<string, any>
  open: boolean
  level: number
  type: DrawerType
  id: string

  // Passing props new way
  routeProps?: string

  close = () => {
    this.open = false
  }

  constructor() {
    makeObservable(this, {
      open: observable,
      level: observable,
      close: action,
    })
  }
}

type DrawerRouteParams = {
  drawerType: DrawerType
  arg: string
}

class DrawerStore {
  // Note: This can contain both opened and closed drawers, we can never remove closed drawers directly from this array since this would break their
  // "animate out" animations. To only deal with the currently opened drawers use the private openedDrawers array instead
  drawers: Drawer[] = []

  constructor() {
    makeObservable<DrawerStore, 'createDrawer' | 'removeClosedDrawersAfterAnimDelay' | 'syncWithUrl'>(this, {
      drawers: observable,
      drawersOpened: computed,
      closeAllDrawers: action,
      openDrawer: action,
      createDrawer: action,
      removeClosedDrawersAfterAnimDelay: action,
      syncWithUrl: action,
    })
  }

  private get openedDrawers() {
    return this.drawers.filter((d) => d.open)
  }

  private navigationStore: NavigationStore

  get drawersOpened() {
    return this.openedDrawers.length
  }

  private initialized = false

  init = (navigationStore: NavigationStore) => {
    if (this.initialized) return

    this.navigationStore = navigationStore
    navigationStore.history.listen(() => {
      if (!this.ignoreUrlChanges) {
        this.syncWithUrl()
      }
    })

    // Parse route data
    this.initialized = true

    // Do we have any instrument drawers in url?
    this.syncWithUrl('url')
  }

  closeDrawer = (level: number) => {
    for (const drawer of this.drawers) {
      if (drawer.level === level) {
        drawer.close()
      }
    }

    drawerStore.syncWithUrl('drawerStore')

    // Once we are sure the animations have completed we can purge away all closed drawers
    this.removeClosedDrawersAfterAnimDelay()
  }

  closeAllDrawers = () => {
    for (const drawer of this.drawers) {
      drawer.close()
    }

    drawerStore.syncWithUrl('drawerStore')

    // Once we are sure the animations have completed we can purge away all closed drawers
    this.removeClosedDrawersAfterAnimDelay()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openDrawer = (type: DrawerType, routeProps?: string, props?: Record<string, any>) => {
    this.createDrawer(type, routeProps, props)
    this.syncWithUrl('drawerStore')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createDrawer = (type: DrawerType, routeProps: string, props?: Record<string, any>) => {
    const newDrawer = new Drawer()
    newDrawer.routeProps = routeProps
    newDrawer.props = props
    newDrawer.id = nanoid()
    newDrawer.open = true
    newDrawer.type = type

    // TODO: Move this logic out of drawerStore and implement max limit for a given drawer type
    // Only allow one order drawer at a time
    const openOrderDrawers = this.openedDrawers.filter((d) => d.type === 'order')

    if (openOrderDrawers.length >= 1 && newDrawer.type === 'order') {
      // Close all old ones
      openOrderDrawers.forEach((orderDrawer) => orderDrawer.close())
    }

    this.drawers.push(newDrawer)

    // More than 3 open? Close until we only have 3
    if (this.drawersOpened > 3) {
      const closeNrOfDrawers = this.drawersOpened - 3

      for (let i = 0; i < closeNrOfDrawers; i++) {
        this.openedDrawers[i].close()
      }
    }

    // Recalculate levels
    let level = 1
    for (const drawer of this.openedDrawers) {
      drawer.level = level
      level++
    }

    // Once we are sure the animations have completed we can purge away all closed drawers
    this.removeClosedDrawersAfterAnimDelay()
  }

  private removeClosedDrawersAfterAnimDelay = async () => {
    await delay(1000)
    this.drawers = this.openedDrawers
  }

  private getDrawerForLevel = (level: number) => {
    return this.openedDrawers.filter((d) => d.level === level)[0]
  }

  // When updating the url by calling syncWithUrl('drawerStore') we don't want to listen to url changes
  private ignoreUrlChanges: boolean = false

  private levelNames = ['', 'd', 'd2', 'd3']

  private syncWithUrl = (sourceOfTruth: 'url' | 'drawerStore' = 'url') => {
    if (sourceOfTruth === 'url') {
      // Opened by refresh in browser / bookmark etc
      this.syncDrawerStoreWithUrl()
    } else if (sourceOfTruth === 'drawerStore') {
      // Opened by user interaction

      // In this case we have changed the internal state of the drawer store (changs drawers array etc)
      // and must simply sync back to the url
      this.syncUrlWithDrawerStore()
    }

    this.removeClosedDrawersAfterAnimDelay()
  }

  private syncUrlWithDrawerStore() {
    const drawerUrlStart = window.location.pathname.indexOf('/d/')
    const urlWithoutDrawers =
      drawerUrlStart >= 0 ? window.location.pathname.substring(0, drawerUrlStart) : window.location.pathname

    let finalUrl = urlWithoutDrawers

    // Ignore already closed dialogs
    for (const drawer of this.openedDrawers) {
      finalUrl = urlJoin(finalUrl, `/d${drawer.level > 1 ? drawer.level : ''}`, drawer.type, drawer.routeProps ?? '')
    }

    this.ignoreUrlChanges = true
    this.navigationStore.push(finalUrl, undefined, true)
    this.ignoreUrlChanges = false
  }

  private syncDrawerStoreWithUrl() {
    for (let level = 1; level <= 3; level++) {
      const levelName = this.levelNames[level]
      const instrumentDrawerPath = `*/${levelName}/:drawerType/:arg?`

      const match = matchPath<DrawerRouteParams>(window.location.pathname, {
        path: instrumentDrawerPath,
        exact: false,
        strict: false,
      })

      if (match) {
        // *** The url has a match at this level ***
        const drawerParams: DrawerRouteParams = {
          drawerType: match.params.drawerType,
          arg: match.params.arg,
        }

        // Do we already have a drawer at this level?
        const existingDrawer = this.getDrawerForLevel(level)
        const drawerRouteProps = drawerParams.arg

        if (!existingDrawer) {
          // There was no drawer at this level we must create it
          this.createDrawer(drawerParams.drawerType, drawerRouteProps)
        } else {
          // There was already a drawer at this level, lets update it.
          // This happens if we are opening say 4 or more drawers and use the back button in the browser
          // Let's compare the existing and new drawer to each other to see if we need to replace it or not
          const existingDrawerUrl = existingDrawer.routeProps
          const newDrawerUrl = urlJoin(drawerParams.drawerType, drawerParams.arg)

          if (existingDrawerUrl !== newDrawerUrl) {
            existingDrawer.close()
            this.createDrawer(drawerParams.drawerType, drawerRouteProps)
          }
        }
      } else {
        // *** The url is empty at this level ***
        // If there is a drawer at this level we remove it
        const existingDrawer = this.getDrawerForLevel(level)

        if (existingDrawer) {
          // There was a drawer for this level but should no longer be, close it
          const matchingDrawers = this.drawers.filter((d) => d.level === level)

          matchingDrawers.forEach((d) => d.close())
        }
      }
    }
  }
}

// Can be moved to context in the future if we wan't to make it prettier
const drawerStore = new DrawerStore()

export function useDrawerStore() {
  return drawerStore
}

export const ActiveDrawerContext = createContext<Drawer>(undefined)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDrawerProps<TProps = any>() {
  const activeDrawer = useContext(ActiveDrawerContext)

  return { routeProps: activeDrawer.routeProps, props: activeDrawer.props as TProps }
}
