import { InstrumentIdContainer, createInstrumentIdContainer } from '@common/instrumentIdContainer'
import { isGuid } from '@common/utils/isGuid'

export function convertInstrumentIdContainerToUrl(instrumentIdContainer: InstrumentIdContainer): string {
  const { infrontInstrument, instrumentIdCarnegie } = instrumentIdContainer || {}
  const { feed, ticker } = infrontInstrument || {}

  // Prioritize return a carnegie id since we have seen cases where the mapping from infront -> carnegie gives the wrong carnegie instrument
  if (instrumentIdCarnegie) return instrumentIdCarnegie

  if (feed && ticker) return `${feed}-${ticker}`

  return null
}

export function convertUrlToInstrumentIdContainer(urlSegment: string): InstrumentIdContainer {
  // Decode URI converts %20 etc to spaces so we don't get any strange characters into the ticker name etc
  const instrumentId = decodeURI(urlSegment)
  let instrumentIdContainer: InstrumentIdContainer = undefined

  if (isGuid(instrumentId)) {
    instrumentIdContainer = createInstrumentIdContainer(instrumentId, undefined)
  } else {
    const dashPosition = instrumentId.indexOf('-')

    let feed = parseInt(instrumentId.substring(0, dashPosition))

    if (isNaN(feed)) {
      feed = undefined
    }

    let ticker = instrumentId.substring(dashPosition + 1)

    if (!ticker) {
      ticker = undefined
    }

    instrumentIdContainer = {
      infrontInstrument: new Infront.Instrument(feed, ticker),
      instrumentIdCarnegie: undefined,
    }
  }

  return instrumentIdContainer
}
