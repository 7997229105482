/* eslint-disable @typescript-eslint/no-unused-vars */
import { UnreadIndicator } from '@/pages/messages/shared/UnreadIndicator'
import { useMessageInboxes } from '@carnegie/digital-channels-frontend'
import {
  Box,
  Breakpoint,
  ButtonFlex,
  Flex,
  Icon,
  IconLogout,
  IconMessage,
  IconMessageFilled,
  LinkUnstyled,
  Paragraph,
  Spacer,
  useDuploTheme,
  useResponsiveProps,
} from '@carnegie/duplo'
import { ShowAtBreakpoint } from '@common/components/ShowAtBreakpoint'
import { useAuth } from '@common/stores/store'
import { css } from '@emotion/react'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { SearchBar } from '../search/SearchBar'
import { CarnegieLogo } from './CarnegieLogo'
import { MenuMobile } from './MenuMobile'

type TopNavBarProps = {
  className?: string
  children?: ReactNode
}

export const TopNavBar = (_: TopNavBarProps) => {
  const rp = useResponsiveProps()
  const auth = useAuth()

  return (
    <>
      <Box mr={rp([16, 16, 16, 32])}>
        <CarnegieLogo />
      </Box>
      {auth.hasLimitedView || <SearchBar />}
      <Row style={{ marginLeft: 'auto' }}>
        {/* Mobile menu */}
        <ShowAtBreakpoint lessThanOrEqual={Breakpoint.Small}>
          <Spacer width={16} />
          <MenuMobile />
        </ShowAtBreakpoint>
        {/* Regular desktop menu */}
        <ShowAtBreakpoint greaterThanOrEqual={Breakpoint.Medium}>
          <MessagesMenuItem />
          <LogoutMenuItem />
        </ShowAtBreakpoint>
      </Row>
    </>
  )
}

TopNavBar.displayName = 'TopNavBar'

const LogoutMenuItem = () => {
  const auth = useAuth()
  const theme = useDuploTheme()
  const { t } = useTranslation()
  return (
    <ButtonFlex
      css={css`
        &:hover,
        &:focus {
          border-radius: 4px;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.18);
          transition: background-color 0.2s ease-in-out;
        }
        &:focus-visible {
          outline: none;
          box-shadow: 0 0 0 3px ${theme.colors['focus-outer-border']};
          border: 1px solid ${theme.colors['focus-inner-border']};
        }
      `}
      alignItems="center"
      p={4}
      ml={12}
      onClick={() => {
        auth.logout()
      }}
    >
      <Icon color="carnegie-pink" icon={IconLogout} />
      <Paragraph variant="body1" color="carnegie-pink" ml={8}>
        {t('Logga ut')}
      </Paragraph>
    </ButtonFlex>
  )
}

LogoutMenuItem.displayName = 'LogoutMenuItem'

const MessagesMenuItem = observer(() => {
  const location = useLocation()
  const { t } = useTranslation()
  const isActive = location.pathname.includes('/messages')

  const theme = useDuploTheme()
  const { inboxes } = useMessageInboxes()
  const hasUnread = inboxes?.some((inbox) => inbox.unreadCount > 0)

  return (
    <Flex position="relative">
      <LinkUnstyled
        css={css`
          padding: 4px;
          display: flex;

          &:hover,
          &:focus {
            border-radius: 4px;
          }
          &:hover {
            background-color: rgba(255, 255, 255, 0.18);
            transition: background-color 0.2s ease-in-out;
            text-decoration: none;
          }
          &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 3px ${theme.colors['focus-outer-border']};
            border: 1px solid ${theme.colors['focus-inner-border']};
          }
        `}
        to="/messages"
        data-testid="messages-menu-item"
      >
        <UnreadIndicator isVisible={hasUnread} />
        <Icon color="carnegie-pink" icon={isActive ? IconMessageFilled : IconMessage} />
        <Paragraph variant="body1" ml={8} css={{ marginTop: 1 }} color="carnegie-pink">
          {t('Meddelanden')}
        </Paragraph>
      </LinkUnstyled>
    </Flex>
  )
})

MessagesMenuItem.displayName = 'MessagesMenuItem'

const Row = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div css={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }} {...props}>
      {props.children}
    </div>
  )
}

Row.displayName = 'Row'
