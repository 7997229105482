import { DuploProvider } from '@carnegie/duplo'
import { useScrollToTop } from '@common/components/ScrollToTop'
import { useDocumentTitle } from '@common/hooks/useDocumentTitle'
import { useFeatures } from '@common/hooks/useFeatures'
import { useApi, useAuth, useNavigation } from '@common/stores/store'
import { getTitleFromRoute } from '@common/utils/urlHelpers'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { observer } from 'mobx-react-lite'
import React, { Suspense, useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router'
import { Link, Router } from 'react-router-dom'
import { useIsTesting } from 'tests/utils/useIsTesting'
import { AppErrorBoundary } from './AppErrorBoundary'
import AppRoutes from './AppRoutes'
import { reactPlugin } from './appInsights'
import { AuthProviderContainer } from './common/components/AuthProviderContainer'
import { useSetupUserActivityManager } from './common/hooks/useUserActivityManager'
import { GlobalStylePortal } from './globalStyles'
import i18n from './i18n'

const LoginSupportPage = React.lazy(() => import('./pages/login/LoginSupportPage'))
const PermissionDeniedPage = React.lazy(() => import('./pages/login/PermissionDeniedPage'))
const NotFoundPage = React.lazy(() => import('./pages/notFound/NotFoundPage'))
const AppOrderPage = React.lazy(() => import('./pages/AppOrderPage'))
const ErrorPage = React.lazy(() => import('./pages/error/ErrorPage'))
const EnvironmentBanner = React.lazy(() => import('./EnvironmentBanner'))

type RequireAuthenticationProps = {
  children: JSX.Element
}

const RequireAuthentication = observer(({ children }: RequireAuthenticationProps) => {
  const auth = useAuth()

  // Initialize the user activity manager that keeps track of if the user is
  // idle and can then logout.
  useSetupUserActivityManager()

  if (auth.isAuthenticating) return null

  if (!auth.isAuthenticated) return <PermissionDeniedPage />

  return children
})

const Pages = observer(() => {
  const { pathname } = useLocation()
  const title = getTitleFromRoute(pathname)

  useDocumentTitle(title)
  useScrollToTop()

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path="/login/support" component={LoginSupportPage} />
        <Route path="/error" component={ErrorPage} />
        <RequireAuthentication>
          <AppErrorBoundary>
            <AppRoutes />
          </AppErrorBoundary>
        </RequireAuthentication>
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  )
})

const App = observer(() => {
  const navigation = useNavigation()
  const { t } = useTranslation()

  return (
    <Router history={navigation.history}>
      <DuploProvider
        componentSettings={{ menu: { size: 'large' }, link: { routerLink: Link } }}
        componentTranslations={{
          select: {
            allValuesText: t('Alla valda'),
            multipleValuesText: (valuesCount) => `${valuesCount} ${t('valda')}`,
          },
        }}
      >
        <Switch>
          <Route path="/webviewsforapp">
            <GlobalStylePortal insideAppWebview />
            <AppForWebView />
          </Route>
          <AuthProviderContainer>
            <AppWeb />
          </AuthProviderContainer>
        </Switch>
      </DuploProvider>
    </Router>
  )
})
App.displayName = 'App'

const AppForWebView = observer(() => {
  const { path } = useRouteMatch()
  const api = useApi()
  const [gotCurityCookie, setGotCurityCookie] = useState(false)
  const auth = useAuth()

  const searchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(searchParams.entries())

  const instrumentIdContainer = {
    infrontInstrument: new Infront.Instrument(parseInt(params.feed), decodeURIComponent(params.ticker)),
  }

  const forcedCarnegieToken = params.carnegieToken || window.carnegieToken
  auth.forcedCarnegieToken = forcedCarnegieToken

  useEffect(() => {
    i18n.changeLanguage(params.language)

    async function fn() {
      try {
        // Send in carnegietoken from app - get an assertiontoken for Curity auth
        const res = await api.loginAssertion(forcedCarnegieToken)
        const assertionToken = res.item?.accessToken

        // This response contains Set-cookie headers which makes us authenticated
        await api.jwtAssertion(assertionToken)
        setGotCurityCookie(true)
      } catch (e) {
        console.log('Error', JSON.stringify(e))
      }
    }
    fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tgwProvider = parseInt(params.tgwProvider)
  const tgwService = parseInt(params.tgwService)
  const tgwEnabled = params.tgwEnabled === 'true'
  const expires = params.expires

  const isNumber = (numberString: string): boolean => !isNaN(Number(numberString))
  const orderId = isNumber(params.orderId) ? parseInt(params.orderId) : undefined

  if (!gotCurityCookie) {
    return null
  }

  return (
    <Route path={`${path}/order`}>
      <AppOrderPage
        infrontToken={params.infrontToken || window.infrontToken}
        tgwProvider={tgwProvider}
        tgwService={tgwService}
        tgwEnabled={tgwEnabled}
        expires={expires}
        xid={params.xid}
        instrumentIdContainer={instrumentIdContainer}
        accountId={params.accountId}
        orderId={orderId}
        phone={params.phone}
      />
    </Route>
  )
})
AppForWebView.displayName = 'AppForWebView'

const AppWeb = observer(() => {
  const auth = useAuth()
  const navigation = useNavigation()
  const features = useFeatures()

  //Handle settings when running tests: ?isTesting=true
  useIsTesting()

  useEffect(() => {
    auth.checkImpersonationViaURLParams()

    if (!auth.impersonationViaUrlParamsInitiated) {
      auth.checkIfLoggedIn()
    }
  }, [auth])

  return auth.impersonationViaUrlParamsInitiated ? null : (
    <>
      {!features.isProd && <EnvironmentBanner />}
      <GlobalStylePortal />
      <AppErrorBoundary>
        <Router history={navigation.history}>
          <Pages />
        </Router>
      </AppErrorBoundary>
    </>
  )
})
AppWeb.displayName = 'AppWeb'

export default withAITracking(reactPlugin, withTranslation()(App), 'PBOnline', 'application-insight-wrapper')
