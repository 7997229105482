import { IconDef } from '@carnegie/duplo'

export const IconCarnegieCLogoFilled: IconDef = {
  viewBox: '0 0 24 24',
  elements: [
    {
      name: 'path',
      props: {
        d: 'M19.9334 5.53439C18.286 8.21759 13.5355 10.5229 9.81932 10.5229C12.8076 7.2728 16.7536 4.43843 18.7457 4.1361C19.8951 3.94714 20.5463 4.55181 19.9334 5.53439ZM18.3243 3.04015C14.6082 3.38027 9.93425 6.3658 7.09925 10.0694C5.03047 9.42692 3.88115 8.21759 3.88115 6.70593C3.88115 5.26985 5.75838 3.90935 8.28689 3.90935C10.1258 3.90935 11.2368 4.32506 11.9647 4.5896C12.1563 4.66518 12.3478 4.40064 12.003 4.24948C10.9303 3.75819 9.47452 3.53144 8.28689 3.53144C4.91554 3.53144 3 5.00531 3 6.8193C3 8.55771 4.22594 10.0316 6.5629 10.674C5.56682 12.1101 4.91554 13.6218 4.57074 15.1334C3.57466 20.1219 7.32912 22.6162 11.3134 19.8574C12.6926 18.8748 14.1867 16.8341 14.7231 14.7177C15.8341 10.3717 9.78101 10.8252 8.09533 14.9445C8.01871 15.209 8.28689 15.3224 8.44013 15.0201C10.3174 11.8078 14.8763 11.052 14.1101 14.5288C13.8036 16.0026 12.616 18.0056 11.0836 19.1771C8.13364 21.3313 5.18371 19.4417 6.48628 15.7003C7.06094 14.0753 7.86547 12.8659 9.3979 11.1275C9.93425 11.1275 10.1258 11.1275 10.394 11.1275C14.3783 11.1275 18.7457 8.78446 20.508 5.76114C21.6573 3.83377 20.7379 2.77561 18.3243 3.04015Z',
        fill: '#11151C',
      },
    },
  ],
}
