import i18n from '@/i18n'
import CarnegieLogoType from '@/pages/login/carnegie-logotype.svg?url'
import {
  Box,
  Breakpoint,
  FlexRow,
  GridContainer,
  GridItem,
  Icon,
  IconExternalLink,
  IconFlagSweden,
  IconFlagUk,
  Image,
  LinkExternal,
  Spacer,
  md,
} from '@carnegie/duplo'
import { ShowAtBreakpoint } from '@common/components/ShowAtBreakpoint'
import { css } from '@emotion/react'
import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

const changeLanguage = (lang?: string) => {
  const url = new URL(window.location.href)
  const urlParams = new URLSearchParams(window.location.search)
  const urlLang = urlParams.get('lang') || 'sv'

  lang = lang || urlLang

  i18n.changeLanguage(lang)

  if (urlLang !== lang) {
    urlParams.set('lang', lang)
    url.search = urlParams.toString()

    localStorage.setItem('browserStorageLang', lang)
    window.location.href = url.toString()
  }
}

export const PublicPage = ({ children }: { children: ReactNode }) => {
  const randomImage = Math.floor(Math.random() * 4) + 1
  const imageSrc = `/images/aside/image-00${randomImage}.webp`
  const location = useLocation()
  const lang = new URLSearchParams(location.search).get('lang') || 'sv'

  useEffect(() => {
    changeLanguage()
  }, [location])

  return (
    <GridContainer spacing={0}>
      <GridItem xs={12} md={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Wrapper>
          <Image src={CarnegieLogoType} />
          <Spacer height={32} />
          {children}
          <Spacer height={16} />
          <ContactUs lang={lang} />
        </Wrapper>
      </GridItem>
      <ShowAtBreakpoint greaterThanOrEqual={Breakpoint.Medium}>
        <GridItem xs={12} md={6} style={{ height: '100vh' }}>
          <Image src={imageSrc} css={{ objectPosition: 'top', objectFit: 'cover' }} height="full" />
        </GridItem>
      </ShowAtBreakpoint>
    </GridContainer>
  )
}

const Wrapper = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={css`
        padding: ${32}px ${8}px;
        width: 432px;
        ${md('padding-top: 48px;')}
      `}
      {...props}
    >
      {props.children}
    </div>
  )
}

const ContactUs = ({ lang }: { lang: string }) => {
  const { t } = useTranslation()

  return (
    <Box>
      <LinkExternal href="/login/support">{t('Kontakta support')}</LinkExternal>
      <Spacer height={8} />
      <FlexRow alignItems="center">
        <LinkExternal href={`https://support.bankid.com/${lang}`} title={t('Hjälp med Mobilt BankID')}>
          {t('Hjälp med Mobilt BankID')}
        </LinkExternal>
        <Icon ml={8} color="astral" icon={IconExternalLink} size={16} />
      </FlexRow>
      <Spacer height={8} />
      <FlexRow alignItems="center">
        <button
          onClick={() => {
            changeLanguage('sv')
          }}
        >
          <Icon icon={IconFlagSweden} />
        </button>
        <Box mr={8} />
        <button
          onClick={() => {
            changeLanguage('en')
          }}
        >
          <Icon icon={IconFlagUk} />
        </button>
      </FlexRow>
    </Box>
  )
}
