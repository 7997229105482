import { useDrawerProps } from '@common/components/drawer/useDrawerStore'
import { useOpenDrawer } from '@common/components/drawer/useOpenDrawer'
import { useInstrumentIdContainerFromUrlSegment } from '@common/hooks/useInstrumentIdContainerFromUrlSegment'
import { createValidInstrumentIdContainer, InstrumentIdContainer } from '@common/instrumentIdContainer'
import { useAuth } from '@common/stores/store'
import { convertInstrumentIdContainerToUrl } from '@common/utils/convertInstrumentIdContainerToFromUrl'

export function useOpenInstrumentDrawer() {
  const { openDrawer } = useOpenDrawer()
  const auth = useAuth()
  if (auth.hasLimitedView) return { openInstrumentDrawer: () => {} }

  const openInstrumentDrawer = (instrumentIdContainer: InstrumentIdContainer, selectedAccountId?: string) => {
    // There are cases when an instrumentIdContainer is passed in that is undefined/null this
    // would normally crash the code. To fix this more easily we create an empty instrumentIdContainer instead with
    // infrontInstrument: null, instrumentIdCarnegie: null and show an error message in the drawer if this object
    // is seen
    const finalInstrumentIdContainer = instrumentIdContainer
      ? createValidInstrumentIdContainer(instrumentIdContainer)
      : { infrontInstrument: null, instrumentIdCarnegie: null }

    const routeProps = convertInstrumentIdContainerToUrl(finalInstrumentIdContainer)

    openDrawer('instrument', routeProps, { selectedAccountId: selectedAccountId })
  }

  return { openInstrumentDrawer }
}

export function useInstrumentDrawerProps() {
  const drawerProps = useDrawerProps<{ selectedAccountId: string }>()

  // Add carnegie id and/or infront id if they are missing
  const { instrumentIdContainer } = useInstrumentIdContainerFromUrlSegment(drawerProps.routeProps)

  return {
    instrumentIdContainer,
    selectedAccountId: drawerProps.props?.selectedAccountId,
  }
}
