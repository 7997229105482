import { UserSettingKey } from '@common/api/response'
import { UserSetting, useUserSetting } from '@common/hooks/useUserSetting'
import { action, computed, makeObservable, observable } from 'mobx'
import { useEffect } from 'react'
import { matchPath } from 'react-router'
import { debounce } from 'throttle-debounce'
import { NavigationStore } from './navigationStore'
import { useRootStore } from './store'

export class SearchStore {
  // Will not be debounced
  query: string = ''

  // Will be debounced
  debouncedQuery: string = ''

  previousSearches: string[] = []

  constructor(private navigationStore: NavigationStore) {
    makeObservable(this, {
      query: observable,
      debouncedQuery: observable,
      previousSearches: observable,
      searchPageOpen: observable,
      setQuery: action,
      activateSearchPage: action,
      clearSearch: action,
      hasText: computed,
      goBack: action,
      addCurrentSearchToPrevousSearches: action,
      loadPreviousSearches: action,
    })

    this.parseLocationChanges()
    // Leaving search route
    navigationStore.history.listen(() => {
      this.parseLocationChanges()
    })
  }

  private searchHistoryUserSetting?: UserSetting<string[]>

  init = (searchHistoryUserSetting?: UserSetting<string[]>) => {
    this.searchHistoryUserSetting = searchHistoryUserSetting
  }

  private parseLocationChanges = () => {
    const searchRouteMatch = matchPath<{ query: string }>(this.navigationStore.currentRoute, {
      path: ['/search/:query/', '/search/'],
      exact: false,
      strict: false,
    })

    // Update query (only if not in sync)
    if (searchRouteMatch) {
      const query = searchRouteMatch?.params?.query ?? ''
      const fixedQuery = query.replace(/-/g, ' ')

      if (this.query !== query) this.setQuery(fixedQuery, false, false)
    } else {
      // No longer matching we are not on the search page anymore so clear search
      // Important we no longer update the url or we will "fight for the url"
      if (this.query) {
        this.clearSearch(false)
      }
    }

    this.searchPageOpen = !!searchRouteMatch
  }

  searchPageOpen = false

  // Allows us to press the back button and go back to where we came from
  comingFromRoute: string = '/'

  setQuery = (query: string, debounce = true, updateUrl = true) => {
    // Before changing the search we keep track of the previous back for the goBack() function
    if (!this.navigationStore.currentRoute.toLowerCase().startsWith('/search')) {
      this.comingFromRoute = this.navigationStore.currentRoute
    }

    this.query = query

    // If we have a button / action etc and we wan't to update the debounced value directly use
    // the syncDebouncedValueDirectly param
    if (debounce) {
      this.syncDebouncedQueryAndLocationDebounced(updateUrl)
    } else {
      // Sets the debouncedQuery etc flags directly
      this.syncDebouncedQueryAndLocation(updateUrl)
    }
  }

  activateSearchPage = () => {
    this.setQuery('', false)
  }

  clearSearch = (updateUrl: boolean) => {
    this.setQuery('', false, updateUrl)
  }

  get hasText() {
    return !!(this.query && this.query.length > 0)
  }

  private syncDebouncedQueryAndLocation = (updateUrl: boolean) => {
    this.debouncedQuery = this.query
    // Replace previous route if we started search otherwise push a new one
    if (updateUrl) {
      if (this.debouncedQuery && this.debouncedQuery.length > 0) {
        this.navigationStore.replace('/search/' + this.debouncedQuery.replace(/ /g, '-'))
      } else {
        this.navigationStore.push('/search/' + this.debouncedQuery.replace(/ /g, '-'))
      }
    }
  }

  private syncDebouncedQueryAndLocationDebounced = debounce(1000, this.syncDebouncedQueryAndLocation)

  goBack = () => {
    this.navigationStore.push(this.comingFromRoute)
  }

  addCurrentSearchToPrevousSearches = () => {
    if (!this.query.replace(/\s/g, '').length) return

    const newPreviousSearches = [this.query, ...this.previousSearches.filter((ps) => ps !== this.query)]
      .filter((item) => item !== '')
      .slice(0, 10)

    this.searchHistoryUserSetting.setValue(newPreviousSearches)
    this.previousSearches = newPreviousSearches
  }

  // This can only be called once the current user has been authenticated and loaded
  loadPreviousSearches() {
    if (this.searchHistoryUserSetting) {
      this.previousSearches = Array.isArray(this.searchHistoryUserSetting.value)
        ? this.searchHistoryUserSetting.value
        : []
    } else {
      throw new Error('You have forgot to call init()')
    }
  }
}

export function useSearch() {
  const searchHistoryUserSetting = useUserSetting<string[]>(UserSettingKey.INSTRUMENT_SEARCHES)
  const searchStore = useRootStore().searchStore

  useEffect(() => {
    searchStore.init(searchHistoryUserSetting)
    searchStore.loadPreviousSearches()
  }, [searchStore, searchHistoryUserSetting])

  return {
    debouncedQuery: searchStore.debouncedQuery,
    query: searchStore.query,
    setQuery: searchStore.setQuery,
    activateSearchPage: searchStore.activateSearchPage,
    clearSearch: searchStore.clearSearch,
    searchPageOpen: searchStore.searchPageOpen,
    hasText: searchStore.hasText,
    previousSearches: searchStore.previousSearches,
    onSearchBlur: searchStore.addCurrentSearchToPrevousSearches,
    goBack: searchStore.goBack,
  }
}
