import { InfrontInstrument } from '@common/api/response'
import { fireTrackEvent } from '@common/utils/analyticsEvent'
import useSWR from 'swr'
import { useApi } from '../stores/store'

const dedupingInterval = 60 * 60 * 1000 // 1 hour in milliseconds

export function useExchangeInfrontIdForCarnegieId(infrontInstrument: InfrontInstrument) {
  const api = useApi()

  const { feed, ticker } = infrontInstrument ?? {}

  const { data, error, isLoading } = useSWR(
    infrontInstrument ? `/instruments/exchange/infront/${feed}/${ticker}` : null,
    async () => {
      const result = await api.getCarnegieInstrumentIdFromInfront(feed, ticker)

      return !result?.error ? result?.item : null
    },
    // We will get an error when we can't exchange infront<->carnegie which causes re-renders so disabling error retrying for now
    { dedupingInterval, shouldRetryOnError: false }
  )

  if (infrontInstrument && error) {
    fireTrackEvent('ExchangeId', 'exchange_infront_id_for_carnegie_id', { error: error })
  }

  return { instrumentIdCarnegie: data, error, isLoading }
}
