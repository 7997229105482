import { Box, LoadingIcon } from '@carnegie/duplo'

type FullPageSpinnerProps = {
  ariaLabel?: string
}

export const FullPageSpinner = ({ ariaLabel = 'loading' }: FullPageSpinnerProps) => {
  return (
    <Box
      display="flex"
      position="fixed"
      height={'100vh'}
      width={'100vw'}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor={'background-canvas'}
    >
      <LoadingIcon aria-label={ariaLabel} />
    </Box>
  )
}
