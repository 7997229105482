import { useAuth } from '@common/stores/store'
import { useState } from 'react'
import { IIdleTimer, useIdleTimer } from 'react-idle-timer'
import { useInterval } from '../components/useInterval'

// Should be global so every component can share the same instance
let sharedIdleTimer: IIdleTimer = undefined

/**
 * Manages user activity. Handles things like logging a user out if inactive (not using keyboard or mouse) for 15 minutes but
 * also provides methods of registering activity to keep this from happening when doing things like watching podcasts or seminar videos.
 * @param idleTimer
 * @returns
 */
export function useUserActivityManager(idleTimer?: IIdleTimer) {
  const auth = useAuth()

  if (idleTimer) {
    sharedIdleTimer = idleTimer
  }

  const registerActivity = (refreshToken: boolean = false) => {
    if (sharedIdleTimer) {
      sharedIdleTimer.activate()
    } else {
      throw new Error(
        'No idle timer instance registered, make sure to call useSetupUserActivityManager(idleTimer) at the root of the app'
      )
    }

    if (refreshToken) {
      auth.refresh()
    }
  }

  return { getRemainingTime: sharedIdleTimer.getRemainingTime, registerActivity }
}

export function useKeepUserLoggedIn(maxTimeInMinutes: number) {
  const { registerActivity } = useUserActivityManager()
  const [startTime] = useState(() => performance.now())

  useInterval(
    () => {
      const passedTimeInMinutes = (performance.now() - startTime) / 60_000
      if (passedTimeInMinutes < maxTimeInMinutes) {
        // Reset the idle timer + refresh the token (just resetting the idle timer can still make us go logged out if we do not interact with the backend)
        registerActivity(true)
      }
    },
    // This time must be less then the time to live of an access token
    4 * 60_000,
    // Run on start also
    true
  )
}

/**
 * Will setup a global user activity manager. This hook should only be called in a single place near the main entry of the application.
 */
export function useSetupUserActivityManager() {
  const auth = useAuth()

  const idleTimer = useIdleTimer({
    timeout: 15 * 60_000, // 15 minutes
    onIdle: () => {
      auth.logout()
    },
    // Enables syncing the idle timer over multiple tabs
    // This is important since otherwise inactive tabs can log out the user
    crossTab: true,
    // Didn't work without setting this also
    syncTimers: 1000,
  })

  useUserActivityManager(idleTimer)
}
