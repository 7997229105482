// Uses cookie-script.com to get the current cookie consent
export function getCookieConsent(): CookieConsentType {
  const cookieScript = window.CookieScript

  if (!cookieScript) {
    console.error("No cookie script loaded, can't get cookie consent state will default to unknown")
    return 'unknown'
  }

  const cookieState = cookieScript.instance?.currentState()?.action as undefined | 'accept' | 'reject'

  if (window.DEBUG_COOKIES) {
    console.log('Current cookie state: ' + cookieState)
  }

  if (cookieState === 'accept') return 'accepted'
  if (cookieState === 'reject') return 'rejected'
  if (cookieState === undefined) return 'unknown'

  return 'unknown'
}

export type CookieConsentType = 'accepted' | 'rejected' | 'unknown'

export function registerCookieConsentEvent(consentChanged: (consentType: CookieConsentType) => void) {
  window.addEventListener('CookieScriptAcceptAll', function () {
    consentChanged(getCookieConsent())
  })

  window.addEventListener('CookieScriptAccept', function () {
    consentChanged(getCookieConsent())
  })
  window.addEventListener('CookieScriptReject', function () {
    consentChanged(getCookieConsent())
  })
}
