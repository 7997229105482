import { format } from 'date-fns'
import { getFeatures } from '../hooks/useFeatures'
import { AuthStore } from '../stores/authStore'

enum Method {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  PUT = 'PUT',
  OPTIONS = 'OPTIONS',
}

type InvokeOptions = { throwErrors?: boolean; rawResponse?: boolean; dataType?: 'json' | 'form-data' }

/*
 * This function works around some inconcistencies in date serialization when using JSON.stringify.
 *
 * From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify:
 * JSON.stringify() converts a value to JSON notation representing it:
 * If the value has a toJSON() method, it's responsible to define what data will be serialized.
 * The instances of Date implement the toJSON() function by returning a string (the same as date.toISOString()).
 *
 * Under the hood toJSON() uses toISOString() to convert the date to a string. toISOString() converts the date to
 * simplified extended ISO format (ISO 8601) with zero UTC offset. This means that a Date-object with a value in
 * CEST (UTC+2) will have its time component turned back two hours to compensate. The issue we ran into was that
 * when we have a date object that looks like this (CEST timezone but time component set to midnight):
 *
 * "Fri Jun 10 2022 00:00:00 GMT+0200 (Central European Summer Time)"
 *
 * toISOString() will turn it into this:
 *
 * "2022-06-09T22:00:00.000Z"
 *
 * Which is the day before. The first date is exactly what's returned from our DatePicker component, which when
 * converted to JSON will instead represent the day before the intended day. We fix this by formatting all dates
 * ourselves instead of relying on the internal toISOString() conversion before sending them to the server.
 */
const dateReplacer = function (key: string, value: unknown) {
  if (this[key] instanceof Date) {
    return format(this[key], 'yyyy-MM-dd HH:mm:ss')
  }

  return value
}

export class Http {
  baseUrl: string
  getAuthStore: () => AuthStore

  constructor(baseUrl: string, getAuthStore: () => AuthStore) {
    this.baseUrl = baseUrl
    this.getAuthStore = getAuthStore
  }

  /** @deprecated Do not use, try to use an endpoint that return json data with our envelope pattern instead */
  getResponse(url: string) {
    const _url = `${this.baseUrl}${url}`
    return this.invokeOrThrow<Response>(_url, Method.GET, undefined, { rawResponse: true })
  }

  /** @deprecated Do not use, try to use an endpoint that return json data with our envelope pattern instead */
  getResponseWithoutThrowing(url: string) {
    const _url = `${this.baseUrl}${url}`
    return this.invokeWithoutThrowing<Response>(_url, Method.GET, undefined, { rawResponse: true })
  }

  /** @deprecated Do not use! Use get instead (which will throw errors for you) */
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  getWithoutThrowing<T extends {}>(url: string) {
    this.simulateFailingApi(url)

    const _url = `${this.baseUrl}${url}`
    return this.invokeWithoutThrowing<T>(_url, Method.GET, undefined)
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  get<T extends {}>(url: string) {
    this.simulateFailingApi(url)

    const _url = `${this.baseUrl}${url}`
    return this.invokeOrThrow<T>(_url, Method.GET, undefined)
  }

  private simulateFailingApi(url: string) {
    const features = getFeatures()

    if (url.includes('/party') && features.testFailingParty) {
      throw new Error('Simulating /party failing!')
    }

    if (url.includes('/holdings') && features.testFailingHoldings) {
      throw new Error('Simulating /holdings failing!')
    }

    if (url.includes('/accounts') && features.testFailingAccounts) {
      throw new Error('Simulating /accounts failing!')
    }

    if (url.includes('/instruments') && features.testFailingInstruments) {
      throw new Error('Simulating /instruments failing!')
    }

    if (url.includes('/communication') && features.testFailingSecureMessages) {
      throw new Error('Simulating /communication failing!')
    }
  }

  /** @deprecated Do not use! Use postFormData instead (which will throw errors for you) */
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  postFormDataWithoutThrowing<T extends {}>(url: string, formData: FormData) {
    const _url = `${this.baseUrl}${url}`
    return this.invokeWithoutThrowing<T>(_url, Method.POST, formData, { dataType: 'form-data' })
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  postFormData<T extends {}>(url: string, formData: FormData) {
    const _url = `${this.baseUrl}${url}`
    return this.invokeOrThrow<T>(_url, Method.POST, formData, { dataType: 'form-data' })
  }

  /**
   *
   * ⚠️POST and PUT will format all Date-objects contained in the data in a non-standard way, using date-fns format() instead of toISOString().
   *
   * See http.ts and the dateReplacer() function for more information
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  post<T extends {}>(url: string, data: object) {
    const _url = `${this.baseUrl}${url}`
    return this.invokeOrThrow<T>(_url, Method.POST, data)
  }

  /**
   * @deprecated Do not use! Use post instead (which will throw errors for you)
   *
   * ⚠️POST and PUT will format all Date-objects contained in the data in a non-standard way, using date-fns format() instead of toISOString().
   *
   * See http.ts and the dateReplacer() function for more information
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  postWithoutThrowing<T extends {}>(url: string, data: object) {
    const _url = `${this.baseUrl}${url}`
    return this.invokeWithoutThrowing<T>(_url, Method.POST, data)
  }

  /**
   * ⚠️POST and PUT will format all Date-objects contained in the data in a non-standard way, using date-fns format() instead of toISOString().
   *
   * See http.ts and the dateReplacer() function for more information
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  put<T extends {}>(url: string, data: object) {
    const _url = `${this.baseUrl}${url}`
    return this.invokeOrThrow<T>(_url, Method.PUT, data)
  }

  /**
   *  @deprecated Do not use! Use put instead (which will throw errors for you)
   * ⚠️POST and PUT will format all Date-objects contained in the data in a non-standard way, using date-fns format() instead of toISOString().
   *
   * See http.ts and the dateReplacer() function for more information
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  putWithoutThrowing<T extends {}>(url: string, data: object) {
    const _url = `${this.baseUrl}${url}`
    return this.invokeWithoutThrowing<T>(_url, Method.PUT, data)
  }

  /** @deprecated Do not use! Use delete instead (which will throw errors for you) */
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  deleteWithoutThrowing<T extends {}>(url: string) {
    const _url = `${this.baseUrl}${url}`

    return this.invokeWithoutThrowing<T>(_url, Method.DELETE)
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  delete<T extends {}>(url: string) {
    const _url = `${this.baseUrl}${url}`

    return this.invokeOrThrow<T>(_url, Method.DELETE)
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  invokeWithoutThrowing<T extends {}>(
    url: string,
    method: Method,
    body?: object,
    options?: Omit<InvokeOptions, 'throwErrors'>
  ): Promise<Prettify<Partial<T & { error: string }>>> {
    // We know that we either return T or an error object, so to make things a little easier we merge them and make them partial
    return this.invoke<T>(url, method, body, { ...options, throwErrors: false }) as Promise<
      Partial<T & { error: string }>
    >
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  invokeOrThrow<T extends {}>(
    url: string,
    method: Method,
    body?: object,
    options?: Omit<InvokeOptions, 'throwErrors'>
  ): Promise<T> {
    // We know that only T can ever be returned (or we will throw)
    return this.invoke(url, method, body, { ...options, throwErrors: true }) as Promise<T>
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  async invoke<T extends {}>(
    url: string,
    method: Method,
    body?: object,
    options?: InvokeOptions,
    shouldRefresh = true
  ): Promise<T | { error: string }> {
    const dataType = options?.dataType ?? 'json'
    const throwErrors = options?.throwErrors ?? false
    const rawResponse = options?.rawResponse ?? false

    const authStore = this.getAuthStore()
    const isReadMethod = method === Method.GET || method === Method.OPTIONS
    let extraHeaders = {}

    const shouldBlockUrl =
      !['analysis', 'transactions'].some((w) => url.includes(w)) && !isReadMethod && authStore.isImpersonated
    if (shouldBlockUrl) {
      console.warn('THIS REQUEST HAS BEEN BLOCKED: ' + url + ' (' + method + ')')
      alert('Du saknar rättighet till åtgärden') //todo byt till snackbar (material ui/duplo)
      return undefined
    }

    if (authStore?.forcedCarnegieToken) {
      console.log('Setting forced carngietoken in header:', authStore.forcedCarnegieToken)
      extraHeaders = { Authorization: `Bearer ${authStore.forcedCarnegieToken}`, ...extraHeaders }
    }
    const headers = {
      Accept: 'application/json',
      // When posting files it's important to NOT include a Content-Type, not even 'multipart/form-data' we need to let the browser handle it instead
      // read more here: https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
      ...(dataType === 'json' ? { 'Content-Type': 'application/json' } : {}),
      ...extraHeaders,
    }

    try {
      const request = {
        credentials: 'include',
        method,
        headers,
        body: dataType === 'json' ? JSON.stringify(body, dateReplacer) : body,
      }

      const response = await fetch(url, request as unknown)

      switch (response.status) {
        case 401:
          if (!shouldRefresh) {
            await authStore.logout()
            return
          }

          console.info('Not authorized to make HTTP request - refreshing auth')

          // Refresh token unless already in progress by another request
          await authStore.refresh()

          // Try again but don't try to refresh the token if we fail instead log out
          return this.invoke(url, method, body, extraHeaders, false)

        case 403:
          authStore.setForbiddenResource(url)
          break
        case 404:
          throw new Error('Not found')

        case 500: {
          const errorBody = await getResponseBody(response)

          if (errorBody.error) {
            throw new Error(errorBody.error)
          } else {
            throw new Error(response.statusText)
          }
        }

        case 200:
        case 204: {
          // NOTE! This will not follow the contract specified (Promise<T & { error }>) and should be removed / refactored!
          if (rawResponse) {
            return response as unknown as T
          }

          const jsonBody = await getResponseBody(response)

          if (throwErrors && jsonBody.error) {
            throw new Error(jsonBody.error)
          }

          return jsonBody
        }

        default:
          throw new Error('Unhandled status code: ' + response.status)
      }
    } catch (error) {
      if (throwErrors) {
        throw error
      }

      // Here we potentially break the contract of T, it is up to the developer to know
      // that they must pass a T that includes { error: string } when using throwErrors = false
      // We should strive to stop using throwErrors = false
      return {
        error: (error as Error)?.message ?? error,
      }
    }
  }
}

async function getResponseBody(response: Response) {
  try {
    if (response.status !== 204) {
      const body = await response.json()
      return body
    }
    return {}
  } catch (e) {
    console.error('Failed to parse response body', e)
    return {}
  }
}

// Makes the type look better when previewed in the IDE
type Prettify<T> = {
  [K in keyof T]: T[K]
} & {}
