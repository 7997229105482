export const getValueColor = (value: number) => {
  if (!value || value === 0) {
    return 'bunker-main'
  } else if (value > 0) {
    return 'astral'
  } else {
    return 'shiraz'
  }
}

function rgbaToString(rgb: [number, number, number], alpha: number): string {
  return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${alpha})`
}

function stringToRgb(rgb: string): [number, number, number] {
  const arrRGB = rgb.replace(/[^\d,]/g, '').split(',')
  return [parseInt(arrRGB[0]), parseInt(arrRGB[1]), parseInt(arrRGB[2])]
}

export function rgbAlpha(rgb: string, alpha: number): string {
  const r = stringToRgb(rgb)
  return rgbaToString(r, alpha)
}
