import { Banner } from '@carnegie/duplo'
import { trackException } from '@common/utils/analyticsEvent'
import { Component, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorPage from './pages/error/ErrorPage'

export class AppErrorBoundary extends Component<{ children: ReactNode }, { error: Error | null }> {
  state = {
    error: undefined,
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error }
  }

  render() {
    if (this.state.error) {
      trackException('OnlineAppErrorBoundary', this.state.error)
      if (this.state.error?.message?.toLowerCase().includes('failed to fetch dynamically imported module')) {
        return <ErrorWithReloadBanner />
      } else {
        return (
          <ErrorPage
            onClose={() => {
              this.setState({ error: undefined })
            }}
            error={this.state.error}
          />
        )
      }
    }

    return this.props.children
  }
}

const ErrorWithReloadBanner = () => {
  const { t } = useTranslation()

  useEffect(() => {
    const timer = setTimeout(() => {
      location.reload()
    }, 5000)
    return () => clearTimeout(timer)
  })

  return (
    <Banner
      severity="information"
      title={t('Ny version tilllgänglig')}
      description={t('Ladda om sidan, eller vänta kvar så sker omladdning automatiskt.')}
    />
  )
}
