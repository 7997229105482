import { UnreadIndicator } from '@/pages/messages/shared/UnreadIndicator'
import {
  Box,
  FlexCol,
  FlexRow,
  Icon,
  IconBarChartPositive,
  IconDef,
  IconHome,
  IconHomeFilled,
  IconLogout,
  IconMessage,
  IconMessageFilled,
  IconUserDetails,
  IconUserDetailsFilled,
  LinkUnstyled,
  Paragraph,
} from '@carnegie/duplo'
import { Drawer } from '@common/components/drawer/Drawer'
import { useActivePageCategory } from '@common/hooks/useActivePageCategory'
import { useAuth } from '@common/stores/store'
import { observer } from 'mobx-react-lite'
import { ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInboxes } from '../messages/shared/useInboxes'
import { IconCarnegieCLogoFilled } from './CarnegieCLogo'
import { Hamburger } from './Hamburger'

export const MenuMobile = observer(() => {
  const auth = useAuth()
  const { t } = useTranslation()
  const [showMenu, setShowMenu] = useState(false)
  const { hasUnreadThreads } = useInboxes()
  const activePageCategory = useActivePageCategory()

  const onClose = useCallback(() => setShowMenu(false), [])

  return (
    <>
      <Hamburger hasUnread={hasUnreadThreads} onClick={() => setShowMenu(true)} />
      <Drawer onClose={onClose} type="menu" open={showMenu}>
        <FlexCol borderBottom="medium" mt={80} pb={16} width={288}>
          <MobileMenuItem
            onClick={onClose}
            to="/overview/home"
            selected={activePageCategory === 'overview'}
            iconFilled={IconHomeFilled}
            icon={IconHome}
          >
            {t('Översikt')}
          </MobileMenuItem>
          {auth.hasLimitedView || (
            <>
              <MobileMenuItem
                to="/market"
                onClick={onClose}
                selected={activePageCategory === 'market'}
                iconFilled={IconBarChartPositive}
                icon={IconBarChartPositive}
              >
                {t('Marknad')}
              </MobileMenuItem>
              <MobileMenuItem
                to="/explore"
                onClick={onClose}
                selected={activePageCategory === 'explore'}
                iconFilled={IconCarnegieCLogoFilled}
                icon={IconCarnegieCLogoFilled}
              >
                {t('Utforska')}
              </MobileMenuItem>
            </>
          )}

          <MobileMenuItem
            to="/profile/mydata"
            onClick={onClose}
            selected={activePageCategory === 'profile'}
            iconFilled={IconUserDetailsFilled}
            icon={IconUserDetails}
          >
            {t('Profil')}
          </MobileMenuItem>
        </FlexCol>
        <FlexCol mt={16} width={288}>
          <MobileMenuItem
            to="/messages"
            onClick={onClose}
            selected={activePageCategory === 'messages'}
            hasUnread={hasUnreadThreads}
            iconFilled={IconMessageFilled}
            icon={IconMessage}
          >
            {t('Meddelanden')}
          </MobileMenuItem>
          <MobileMenuItem
            onClick={() => {
              auth.logout()
            }}
            iconFilled={IconLogout}
            icon={IconLogout}
          >
            {t('Logga ut')}
          </MobileMenuItem>
        </FlexCol>
      </Drawer>
    </>
  )
})

type MobileMenuItemProps = {
  className?: string
  children?: ReactNode
  iconFilled: IconDef
  icon: IconDef
  selected?: boolean
  hasUnread?: boolean
  to?: string
  onClick?: () => void
}

const MobileMenuItem = ({
  children,
  className,
  iconFilled,
  icon,
  selected = false,
  hasUnread,
  to,
  onClick,
}: MobileMenuItemProps) => {
  return (
    <ButtonOrLinkWrapper to={to} onClick={onClick}>
      <FlexRow
        p={8}
        alignItems="center"
        css={{ borderRadius: 4 }}
        backgroundColor={selected ? 'primary-navigation-active' : undefined}
        height={48}
        className={className}
      >
        <Box position="relative" mr={8}>
          <UnreadIndicator isVisible={hasUnread} />
          <Icon icon={selected ? iconFilled : icon} size={24} />
        </Box>
        <Paragraph variant="body1">{children}</Paragraph>
      </FlexRow>
    </ButtonOrLinkWrapper>
  )
}

const ButtonOrLinkWrapper = ({ children, onClick, to }: { children: ReactNode; onClick?: () => void; to?: string }) => {
  if (to) {
    return (
      <LinkUnstyled onClick={onClick} to={to}>
        {children}
      </LinkUnstyled>
    )
  }

  // For buttons (onClick is set)
  return <button onClick={onClick ? onClick : () => {}}>{children}</button>
}
