import { createBrowserHistory, History } from 'history'

// We only want to create one history object or fast-refresh seems to break stuff
const browserHistory = createBrowserHistory()

export class NavigationStore {
  history: History

  // Allows us to more easily build back button logic since we can track the full user history
  private internalHistory: string[] = []

  constructor() {
    this.history = browserHistory

    this.history.listen(() => {
      this.internalHistory.push(this.currentRoute)
    })
  }

  goBack = () => {
    this.history.goBack()
  }

  push = (route: string, state?: unknown, preserveQuery = false) => {
    let finalUrl = route

    if (preserveQuery) {
      finalUrl = this.addQueryString(finalUrl, this.currentQuery)
    }

    this.internalHistory.push(finalUrl)

    this.history.push(finalUrl, state)
  }

  getBackRoute = (allowedRoutes: string[], fallbackRoute: string) => {
    const internalHistoryReversed = [...this.internalHistory].reverse()

    for (const prevRoute in internalHistoryReversed) {
      if (allowedRoutes.includes(prevRoute)) {
        return prevRoute
      }
    }

    return fallbackRoute
  }

  get currentState() {
    return this.history.location.state
  }

  get currentQuery() {
    return this.history.location.search
  }

  replace = (route: string, state?: unknown) => {
    this.history.replace(route, state)
  }

  get currentRoute() {
    return this.history.location?.pathname
  }

  private addQueryString(url: string, query: string): string {
    // Parse the input URL

    // Check if the URL is relative or absolute
    const isRelative = !/^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(url)

    // If the URL is relative, create a dummy base URL
    const baseUrl = isRelative ? 'http://carnegie.se' : '' // This is just a dummy url to make the new URL(...) work

    const urlObj = new URL(url, baseUrl)

    // Parse the input query string into an object
    const newParams = new URLSearchParams(query)

    // Merge new query parameters with the existing ones
    newParams.forEach((value, key) => {
      urlObj.searchParams.set(key, value)
    })

    // Return the updated URL as a string
    const updatedUrl = urlObj.toString()
    return isRelative ? updatedUrl.replace(baseUrl, '') : updatedUrl
  }
}
