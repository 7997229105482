import { Button, Card, FlexCol, Heading5, Paragraph } from '@carnegie/duplo'
import '@carnegie/duplo/lib/duplo.css'
import { useAuth } from '@common/stores/store'
import { useTranslation } from 'react-i18next'
import { PublicPage } from '../PublicPage'

const PermissionDeniedPage = () => {
  const authStore = useAuth()
  const { t } = useTranslation()
  return (
    <PublicPage>
      <Card>
        <FlexCol alignItems="center" p={24}>
          <Heading5 mb={32}>{t('Behörighet saknas')}</Heading5>
          <Paragraph variant="body1" textAlign="center">
            {t('Du saknar behörighet för att kunna logga in, kontakta oss för hjälp.')}
          </Paragraph>
          <Button
            onClick={() => {
              authStore.finalizeLogout()
            }}
            variant="primary"
            size="large"
            mt={32}
          >
            {t('Försök igen')}
          </Button>
        </FlexCol>
      </Card>
    </PublicPage>
  )
}

export default PermissionDeniedPage
