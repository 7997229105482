import { useAuth } from '@common/stores/store'
import { useEffect } from 'react'

export const LogoutPage = () => {
  const auth = useAuth()

  useEffect(() => {
    auth.finalizeLogout()

    //Used in curity when Global Logout is true
    //Meaning we want to logout from all applications
    //Curity loads this page thru an Iframe and we need to message the main page that we're done logging out.
    window.parent?.postMessage('CARNEGIE_LOGOUT_COMPLETE', '*')
  }, [auth])

  return <div />
}
