import { DrawerType, useDrawerStore } from './useDrawerStore'

export function useOpenDrawer() {
  const drawerStore = useDrawerStore()

  const openDrawer = (
    drawerType: DrawerType,
    routeProps?: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props?: Record<string, any>
    // transitionType: 'replace' | 'append' = 'append'
  ) => {
    drawerStore.openDrawer(drawerType, routeProps, props)
  }

  return { openDrawer }
}
