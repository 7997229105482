import { useEffect, useState } from 'react'

export function useDelayMount(delay: number) {
  const [showComponent, setShowComponent] = useState(false)

  useEffect(() => {
    if (delay > 0) {
      const timeout = setTimeout(() => setShowComponent(true), delay)
      return () => clearTimeout(timeout)
    }
  }, [delay])

  return { showComponent: delay === 0 ? true : showComponent }
}
