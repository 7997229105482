import {
  Banner,
  Box,
  Breakpoint,
  Button,
  ClickAwayListener,
  FlexCol,
  FlexRow,
  Heading5,
  Paragraph,
  useBreakpoint,
} from '@carnegie/duplo'
import { useNavigation } from '@common/stores/store'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import FeedbackImage from './Feedback.png'

type ErrorPageProps = {
  error: Error
  onClose: () => void
}

const ErrorPage = observer(({ error, onClose }: ErrorPageProps) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const email = 'feedbackapp@carnegie.se'
  const subject = 'Feedback om PB Online'
  const hostname = window.location.hostname
  const navigation = useNavigation()

  const isExtraSmall = breakpoint <= Breakpoint.Xs

  const onClick = () => {
    onClose()
  }

  return (
    <ClickAwayListener onClickAway={onClick}>
      <Box>
        <Box display="flex">
          <FlexCol width={isExtraSmall ? 'full' : '1/2'} p={32}>
            {isExtraSmall && <img alt="feedback" src={FeedbackImage} />}
            <Heading5 pt={64}>{t('Något gick fel')}</Heading5>

            <Paragraph variant="body1">
              {t(
                'Sidan du ville besöka kan inte visas. Vi jobbar kontinuerligt på våra tjänster för att du ska få en så bra upplevelse som möjligt.'
              )}
            </Paragraph>
            <Paragraph variant="body1" mt={16}>
              {t('Vill du ge oss feedback tar vi tacksamt emot det.')}
            </Paragraph>
            <FlexRow mt={16}>
              <Button
                mr={16}
                variant="primary"
                size="small"
                width="auto"
                onClick={() => {
                  navigation.goBack()
                  // Separate button for close?
                  //onClose()
                }}
              >
                {t('Gå tillbaka')}
              </Button>
              <Button
                variant="secondary"
                size="small"
                width="auto"
                onClick={() => {
                  window.location.href = `mailto:${email}?subject=${subject}`
                }}
              >
                {t('Ge feedback')}
              </Button>
            </FlexRow>

            <Box mt={32}>
              {error && hostname !== 'pb.carnegie.se' && (
                <Banner severity="critical" title={error.name} description={error.message} />
              )}
            </Box>
          </FlexCol>
          {!isExtraSmall && (
            <FlexCol width="1/2" p={32}>
              <img alt="feedback" src={FeedbackImage} />
            </FlexCol>
          )}
        </Box>
      </Box>
    </ClickAwayListener>
  )
})

export default ErrorPage
