export function getImpersonationKeyValuesFromSearchParams(query = new URLSearchParams(window.location.search)) {
  const urlParamsToKeys = {
    acr: 'acr_values',
    'act-as': 'act_as',
    method: 'method',
  }
  const params = []
  Object.entries(urlParamsToKeys).forEach(([urlParam, key]) => {
    const value = query.get(urlParam)
    if (!value) return

    params.push({ key, value })
  })
  return params
}
