import { useExchangeCarnegieIdForInfrontId } from '@common/hooks/useExchangeCarnegieIdForInfrontId'
import { useExchangeInfrontIdForCarnegieId } from '@common/hooks/useExchangeInfrontIdForCarnegieId'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'
import { isGuid } from '@common/utils/isGuid'

export function useInstrumentIdContainerFromUrlSegment(urlSegment: string) {
  const data: InstrumentCarnegieIdOrInfrontId = convertUrlToId(urlSegment)
  let validInstrumentIdContainer: InstrumentIdContainer | undefined = undefined

  if (isInfrontId(data)) {
    validInstrumentIdContainer = {
      infrontInstrument: {
        ...data,
      },
      instrumentIdCarnegie: undefined,
    }
  } else {
    validInstrumentIdContainer = {
      infrontInstrument: { feed: undefined, ticker: undefined },
      ...data,
    }
  }

  // Add carnegie id and/or infront id if they are missing
  const { instrumentIdContainer } = useCompleteInstrumentIdContainer(validInstrumentIdContainer)

  return {
    instrumentIdContainer,
  }
}

const convertUrlToId = (urlArg: string): InstrumentCarnegieIdOrInfrontId => {
  const instrumentId = decodeURI(urlArg)
  if (isGuid(instrumentId)) return { instrumentIdCarnegie: instrumentId }

  const data = parseInfrontId(instrumentId)

  return { ...data }
}

const parseInfrontId = (instrumentId: string) => {
  const dashPosition = instrumentId.indexOf('-')

  let feed = parseInt(instrumentId.substring(0, dashPosition))

  if (isNaN(feed)) {
    feed = undefined
  }

  let ticker = instrumentId.substring(dashPosition + 1)

  if (!ticker) {
    ticker = undefined
  }

  return { feed, ticker }
}

type InstrumentCarnegieIdOrInfrontId =
  | {
      feed?: number
      ticker?: string
    }
  | {
      instrumentIdCarnegie?: string
    }

/**
 * Given a url segment in the form of [carnegieId] or [feed-ticker] return an InstrumentIdContainer by filling out the missing
 * data using our exchange endpoints.
 * @param urlSegment
 * @returns
 */

function isInfrontId(
  instrumentCarnegieIdOrInfrontId: InstrumentCarnegieIdOrInfrontId
): instrumentCarnegieIdOrInfrontId is {
  feed: number
  ticker: string
} {
  const dataAsFeedAndTicker = instrumentCarnegieIdOrInfrontId as {
    feed?: number
    ticker?: string
  }

  return dataAsFeedAndTicker.feed !== undefined && dataAsFeedAndTicker.ticker !== undefined
}

/**
 * Returns a completed instrument id container by using the exchange endpoints for filling in missing data.
 * @param instrumentIdContainer
 * @returns
 */
const useCompleteInstrumentIdContainer = (instrumentIdContainer: InstrumentIdContainer) => {
  // Get infront instrument from carnegie-id
  const { instrumentIdCarnegie: originalInstrumentIdCarnegie, infrontInstrument: originalInfrontInstrument } =
    instrumentIdContainer ?? {}

  const hasValidInfrontId = !!(originalInfrontInstrument?.feed && originalInfrontInstrument?.ticker)
  const hasValidCarnegieId = !!originalInstrumentIdCarnegie

  const { infrontInstrument: completedInfrontInstrument, isLoading: isLoadingInfrontInstrument } =
    useExchangeCarnegieIdForInfrontId(!hasValidInfrontId ? instrumentIdContainer?.instrumentIdCarnegie : null)

  // Get carnegie id from feed + ticker
  const { instrumentIdCarnegie: completedInstrumentIdCarnegie, isLoading: isLoadingInstrumentIdCarnegie } =
    useExchangeInfrontIdForCarnegieId(!hasValidCarnegieId ? instrumentIdContainer?.infrontInstrument : null)

  // We only return a completed instrument object when all exchanges are done
  const completeInstrumentIdContainer =
    !isLoadingInfrontInstrument && !isLoadingInstrumentIdCarnegie
      ? {
          instrumentIdCarnegie: hasValidCarnegieId ? originalInstrumentIdCarnegie : completedInstrumentIdCarnegie,
          infrontInstrument: hasValidInfrontId ? originalInfrontInstrument : completedInfrontInstrument,
        }
      : undefined

  return {
    instrumentIdContainer: completeInstrumentIdContainer,
  }
}
