import { UnreadIndicator } from '@/pages/messages/shared/UnreadIndicator'
import { ButtonBox, colors, FlexCol } from '@carnegie/duplo'

type Props = {
  hasUnread?: boolean
  onClick: () => void
}

export const Hamburger = ({ onClick, hasUnread = false }: Props) => {
  return (
    <ButtonBox display="inline-block" position="relative" onClick={() => onClick()}>
      <UnreadIndicator isVisible={hasUnread} top={8} />
      <FlexCol justifyContent="center" alignItems="center" width={32} height={48}>
        <Bar />
        <Bar />
        <Bar />
      </FlexCol>
    </ButtonBox>
  )
}

const Bar = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div css={{ backgroundColor: `${colors.carnegiePink}`, margin: '4px 0px', height: 1, width: 24 }} {...props}>
      {props.children}
    </div>
  )
}
