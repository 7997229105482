export const scrollToTop = () => {
  window.scrollTo(0, 0)

  // const app = document.getElementById('app')
  // if (app) {
  //   app
  // } else {
  //   console.error("scrollToTop() Element with id=app doesn't exist")
  // }
}
