import { css } from '@emotion/react'

export const Subtitle1Style = css`
  color: rgb(17, 21, 28);
  font-family: Roboto;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
`

export const Body1Style = css`
  color: rgb(17, 21, 28);
  font-family: Roboto;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
`

export const Body2Style = css`
  color: rgb(17, 21, 28);
  font-family: Roboto;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
`

export const OverlineStyle = css`
  color: rgb(105, 107, 112);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-transform: uppercase;
  display: block;
`
