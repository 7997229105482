import { useEffect } from 'react'
import { getOriginalDocumentTitle } from '../utils/urlHelpers'

export function useDocumentTitle(title: string) {
  useEffect(() => {
    if (title) {
      document.title = title + ' - ' + getOriginalDocumentTitle()
    }
  }, [title])
}
