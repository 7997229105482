import { useEffect, useRef } from 'react'

export function useInterval(callback: () => void, delay: number, callOnStart = false) {
  const intervalRef = useRef(null)
  const savedCallback = useRef(callback)

  useEffect(() => {
    if (callOnStart) {
      callback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => savedCallback.current()
    intervalRef.current = setInterval(tick, delay)
    return () => clearInterval(intervalRef.current)
  }, [delay])
}
