export function marketListPath({
  country,
  item,
  page = 0,
  sortBy,
  sortOrder,
  filter,
  tab,
}: {
  country: string
  item: string
  page?: number
  sortBy?: string
  sortOrder?: string
  filter?: string
  tab?: string
}) {
  item = item.replace(/ /g, '_') // Large%20%Cap%Stockholm -> Large_Cap_Stockholm
  country = country?.toLowerCase()
  item = item?.toLowerCase()

  let path = `/market/stocks/${country}/${item}`
  const extra = []

  if (page && page !== 1) extra.push(`page=${page}`) // for first page, clean out page=1 param
  if (sortBy) extra.push(`sortBy=${sortBy}`)
  if (sortOrder) extra.push(`sortOrder=${sortOrder}`)
  if (filter) extra.push(`filter=${filter}`)
  if (tab && tab !== 'overview') extra.push(`tab=${tab}`)

  path = `${path}?${extra.join('&')}`
  return path
}

// Basic routes
const routeTitles = {
  '/': '',
  '/article': 'Artikel',
  '/accounts': 'Konto',
  '/explore': 'Aktuellt - Utforska',
  '/explore/podcast': 'Podcast - Utforska',
  '/explore/seminar': 'Seminarium - Utforska',
  '/explore/strategy': 'Strategi - Utforska',
  '/explore/marketcomment': 'Marknadskommentar - Utforska',
  '/explore/investmentideas': 'Investeringsidéer  - Utforska',
  '/explore/research': 'Analys - Utforska',
  '/instrument': 'Instrument',
  '/login': 'Logga in',
  '/login/support': 'Support',
  '/market': 'Marknad',
  '/market/funds': 'Fonder - Marknad',
  '/market/news': 'Nyheter - Marknad',
  '/market/other': 'Övriga  - Marknad',
  '/market/overview': 'Översikt - Marknad',
  '/market/stocks': 'Aktier - Marknad',
  '/market/watchlists': 'Bevakningar - Marknad',
  '/market/corporate-events': 'Bolagshändelser - Marknad',
  '/messages': 'Meddelanden',
  '/news': 'Nyhet',
  '/order': 'Orderläggning',
  '/overview/accounts': 'Konton - Översikt',
  '/overview/article': 'Artikel - Översikt',
  '/overview/holdings': 'Innehav - Översikt',
  '/overview/home': 'Hem - Översikt',
  '/overview/orders': 'Order - Översikt',
  '/overview/transactions': 'Transaktioner - Översikt',
  '/podcast': 'Podcast',
  '/profile/mydata': 'Mina uppgifter - Profil',
  '/profile/contact': 'Kontakt - Profil',
  '/profile/reports': 'Rapporter - Profil',
  '/profile/settings': 'Inställningar - Profil',
  '/research/research-and-comments': 'Analyser & kommentarer',
  '/search': 'Sök',
  '/seminar': 'Seminarium',
}
// Dynamic drawer routes
const dynamicRouteTitles = {
  '/article': 'Artikel',
  '/instrument': 'Instrument',
  '/news': 'Nyhet',
  '/order': 'Order',
  '/podcast': 'Podcast',
  '/research': 'Analys',
  '/research_comments': 'Kommentar',
  '/seminar': 'Seminarium',
  '/transfers': 'Överföringar',
}

const originalDocumentTitle = document.title

export function getOriginalDocumentTitle() {
  return originalDocumentTitle
}

export function getTitleFromRoute(pathname: string): string {
  const firstRoute =
    '/' +
    pathname
      .split('/')
      .slice(0, 2)
      .filter((part) => part)[0]

  const firstRoute2 =
    '/' +
    pathname
      .split('/')
      .slice(0, 3)
      .filter((part) => part)
      .join('/')

  //for transferDrawer
  const transferDrawerRoute = '/' + pathname.split('/').filter((part) => part)[2]
  const isTransferDrawerRoute = transferDrawerRoute === '/transfers'

  const activeRoute =
    '/' +
    pathname
      .split('/')
      .slice(-2)
      .filter((part) => part)[0]

  const title = routeTitles[firstRoute]
  const title2 = routeTitles[firstRoute2]
  const dynamicTitle = dynamicRouteTitles[activeRoute]

  if (activeRoute === '/instrument' || activeRoute === '/order') {
    const parts = pathname.split('/').slice(-2).join('').split('-')
    const ticker = parts.slice(1).join(' ').toUpperCase()
    // console.info('Route - instrument title:', ticker)
    return `${ticker} - ${dynamicTitle}`
  }

  if (isTransferDrawerRoute) {
    return `${dynamicRouteTitles[transferDrawerRoute]}`
  }

  if (dynamicTitle) {
    return `${dynamicTitle}`
  }

  if (title2) {
    return `${title2}`
  }

  if (title) {
    return `${title}`
  }

  return getOriginalDocumentTitle()
}

// Regex tester for this regex: https://regexr.com/6p122
export function convertLinksToAnchorTags(input: string) {
  return input.replace(
    /(https?:\/\/|www.(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(:?\d*)\/?([A-Za-z_/0-9\-#.|%20|%C3%A5|%C3%A4|%C3%B6|%C3%85|%C3%84|%C3%96]*)\??([A-Za-z_/0-9\-#=&:]*)/gm,
    function (match) {
      let href = match
      if (!match.startsWith('http')) {
        href = `http://${match}`
      }
      return `<a href="${href}" target="_blank" rel="noopener noreferrer">${match}</a>`
    }
  )
}

// Regex tester for this regex: https://regexr.com/6p3cm
export function convertEmailToAnchorTags(input: string) {
  return input.replace(/([a-zA-Z0-9._-]+@([a-zA-Z0-9_-]+\.)+[a-zA-Z0-9_-]+)/gi, function (match) {
    return `<a href="mailto:${match}" target=_self>${match}</a>`
  })
}
