export function getConfig() {
  if (import.meta.env.MODE !== 'production') {
    // local
    return {
      AI_KEY: import.meta.env.VITE_AI_KEY,
    }
  } else {
    // Release
    return window.CONFIG
  }
}
