import i18n from '@/i18n'
import { SystemMessageResponse } from '@common/api/response'
import { useApi, useLocalStorage } from '@common/stores/store'
import useSWR from 'swr'

const LOCAL_STORAGE_KEY = 'CLOSED_SYSTEMMESSAGES'

type SeenMessages = Record<string, string>

export function useSystemMessage(routeOrKey = null) {
  const api = useApi()
  const localStorage = useLocalStorage()

  const alreadySeenSystemMessages = localStorage.getItem<SeenMessages>(LOCAL_STORAGE_KEY, 'global') ?? {}
  const { data, mutate } = useSWR(
    'systemmessage' + routeOrKey,
    () => api.fetchSystemMessage(i18n.language, routeOrKey),
    {
      refreshInterval: 60000,
    }
  )

  const messages = data?.filter((m) => shouldShowMessage(m, alreadySeenSystemMessages))

  function onClose(messageId: string) {
    const seenMessages = localStorage.getItem<SeenMessages>(LOCAL_STORAGE_KEY, 'global') ?? {}
    const closedDate = new Date().toISOString()
    seenMessages[messageId] = closedDate
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(seenMessages), 'global')
    mutate(data.filter((s) => s.id !== messageId))
  }

  return { messages, onClose }
}

function shouldShowMessage(m: SystemMessageResponse, alreadySeenSystemMessages: SeenMessages) {
  const seenMessage = alreadySeenSystemMessages[m.id]
  if (!seenMessage) return true

  try {
    const closedDate = new Date(seenMessage)
    closedDate.setHours(0, 0, 0, 0)

    const now = new Date()
    now.setHours(0, 0, 0, 0)

    return now > closedDate
  } catch (err) {
    console.log(err)
  }

  return true
}
