import Drawer from '@common/components/drawer/Drawer'
import { ActiveDrawerContext, useDrawerStore } from '@common/components/drawer/useDrawerStore'
import { useNavigation } from '@common/stores/store'
import { observer } from 'mobx-react-lite'
import { ReactNode, useEffect } from 'react'
import { AppErrorBoundary } from './AppErrorBoundary'

type DrawersProps = {
  className?: string
  children?: ReactNode
  renderDrawer: (drawerType: string) => ReactNode
}

export const Drawers = observer(({ renderDrawer }: DrawersProps) => {
  // This will set up three routes one for each level we support

  const nav = useNavigation()
  const drawerStore = useDrawerStore()

  useEffect(() => {
    drawerStore.init(nav)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {drawerStore.drawers.map((drawer) => {
        const drawerContent = renderDrawer?.(drawer.type)

        if (!drawerContent)
          throw new Error(
            `Drawer of type "${drawer.type}" not supported, make sure to add it to the renderDrawer prop for the <Drawers> component!`
          )

        return (
          <Drawer key={drawer.id} level={drawer.level} open={drawer.open}>
            <AppErrorBoundary>
              <ActiveDrawerContext.Provider value={drawer}>{drawerContent}</ActiveDrawerContext.Provider>
            </AppErrorBoundary>
          </Drawer>
        )
      })}
    </>
  )
})

Drawers.displayName = 'Drawers'
