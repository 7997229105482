import { AuthStore } from './authStore'

type SupportedStores = 'localStorage' | 'sessionStorage'

export interface WebStorage {
  userScopeAvailable(): boolean
  setItem(key: string, object: string | object, scope: 'global' | 'user'): void
  getItem<T = string | object>(key: string, scope: 'global' | 'user'): T | null
  removeItem(key: string, scope: 'global' | 'user'): void
}

class WebStorageStore implements WebStorage {
  private store: Storage

  constructor(private authStore: AuthStore, storeType: SupportedStores) {
    if (!authStore) throw new Error('You did not provide an auth store!')
    this.store = window[storeType]
  }

  private getScopedKey(key: string, scope: 'global' | 'user') {
    const userPrefix = this.authStore.userPrefix ?? ''

    if (scope === 'user' && !userPrefix)
      throw new Error(
        "Could not get a user prefix for logged in user this means I can't get local storage key: " +
          key +
          ' scoped to user, you can check if the user scope is available by calling the method userScopeAvailable()'
      )

    return scope === 'global' ? key.toUpperCase() : userPrefix.toUpperCase() + '_' + key.toUpperCase()
  }

  userScopeAvailable() {
    return !!this.authStore.userPrefix
  }

  setItem(key: string, object: string | object, scope: 'global' | 'user') {
    if (typeof object === 'object') {
      this.store.setItem(this.getScopedKey(key, scope), JSON.stringify(object))
    } else {
      this.store.setItem(this.getScopedKey(key, scope), object)
    }
  }

  getItem<T = string | object>(key: string, scope: 'global' | 'user'): T | null {
    const value = this.store.getItem(this.getScopedKey(key, scope))
    return value && ['{', '['].includes(value[0]) ? JSON.parse(value) : value
  }

  removeItem(key: string, scope: 'global' | 'user') {
    this.store.removeItem(this.getScopedKey(key, scope))
  }
}

export class LocalStorageStore extends WebStorageStore {
  constructor(authStore: AuthStore) {
    super(authStore, 'localStorage')
  }
}

export class SessionStorageStore extends WebStorageStore {
  constructor(authStore: AuthStore) {
    super(authStore, 'sessionStorage')
  }
}
