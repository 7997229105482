import { FlexRow, Icon, IconSearch, Input } from '@carnegie/duplo'
import { useSearch } from '@common/stores/searchStore'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const SearchBar = observer(() => {
  const { t } = useTranslation()

  const { query, setQuery, activateSearchPage, clearSearch, hasText, onSearchBlur, searchPageOpen } = useSearch()

  return (
    <FlexRow
      alignItems="center"
      css={{
        maxWidth: 672,
      }}
      onClick={() => {
        // In this case we want to show the search page when clicking the wrapper but NOT when tabbing using keyboard, so
        // in this case we should not make the wrapper a button etc (and we aren't allowed either since we have an input inside)
        // searchStore.setIsActive(true)
        if (!searchPageOpen) {
          activateSearchPage()
        }
      }}
      width="100%"
    >
      <Input
        type="search"
        id="search-field"
        size="small"
        data-testid="search-bar"
        autoComplete="off"
        value={query}
        width="100%"
        onChange={(event) => setQuery(event.target.value)}
        placeholder={t('Sök värdepapper')}
        clearButton={hasText}
        onBlur={() => onSearchBlur()}
        onClearClick={() => {
          clearSearch(true)
        }}
        startIcon={<Icon color={'text-low-emphasis'} icon={IconSearch} size={20} />}
      />
    </FlexRow>
  )
})
