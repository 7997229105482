import { OfferingAnswer, OfferingType, OfferingsResponse } from '@common/api/response'
import { useApi } from '@common/stores/store'
import qs from 'qs'
import { useMemo } from 'react'
import { mutate } from 'swr'
import { useHttpSwr } from '../../pages/messages/shared'

type UseOfferingsOptions = {
  limit?: number
  type: `${OfferingType}`
  offeringId?: number
  showOlderThanToday?: boolean
  accountIds?: string[]
}

export function useOfferings(options?: UseOfferingsOptions) {
  const api = useApi()
  let query = qs.stringify(options)
  query = query ? '?' + query : query

  const { data, error, isValidating } = useHttpSwr<{ items: OfferingsResponse[]; totalItems: number }>(
    `/digital-channels/corporate-actions${query}`
  )

  const registerCorporateActions = async (offeringAnswers?: OfferingAnswer[]) => {
    const result = await api.registerCorporateActions(offeringAnswers)

    // Refetch all hooks that use corporate-actions (not just the one that has this specific query string)
    mutate((key) => {
      return typeof key === 'string' && key.startsWith('/digital-channels/corporate-actions')
    })

    return result
  }

  // For customers without offerings we might get a 204 from the backend, we must handle this case also and
  // because of how our http class currently handles data without json we get an empty object as a response and must check for it
  const noContent = data !== null && data !== undefined && typeof data === 'object' && !data.items

  const emptyOfferings = useMemo<OfferingsResponse[]>(() => [], [])
  const offerings = noContent ? emptyOfferings : data?.items
  const totalItems = noContent ? 0 : data?.totalItems

  // Extend the offerings object with the hasResponded property
  const extendedOfferings: Offering[] = useMemo(
    () =>
      offerings?.map((offering) => {
        const firstOffer = offering.accountOfferings?.[0]
        const hasResponded: boolean | 'mixed' = offering.accountOfferings.every(
          (accountOffering) => accountOffering.hasResponded === firstOffer?.hasResponded
        )
          ? firstOffer?.hasResponded ?? false
          : 'mixed'

        const extendedOffering: Offering = { ...offering, hasResponded }
        return extendedOffering
      }),
    [offerings]
  )

  return { offerings: extendedOfferings, error, isValidating, totalItems, registerCorporateActions }
}

export type Offering = { hasResponded: boolean | 'mixed' } & OfferingsResponse

export type GroupedOffering = {
  offeringId: number
  instrumentName?: string | null
  lastResponseDate?: string | null
  offeringType: OfferingType
  hasResponded: boolean | 'mixed'
  accountIds?: string[]
}
