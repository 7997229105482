import { makeObservable, observable } from 'mobx'
import { Api } from '../api/api'
import { Http } from '../api/http'
import { AuthStore } from './authStore'
import { InfrontStore } from './infrontStore'
import { NavigationStore } from './navigationStore'
import { SearchStore } from './searchStore'
import { WatchlistStore } from './watchlistStore'
import { LocalStorageStore, SessionStorageStore } from './webStore'

export class RootStore {
  constructor() {
    makeObservable(this, {
      http: observable,
      api: observable,
      navigationStore: observable,
      authStore: observable,
      localStorage: observable,
      sessionStorage: observable,
      searchStore: observable,
      infrontStore: observable,
      watchlistStore: observable,
    })

    this.init()
  }

  http: Http
  api: Api
  navigationStore: NavigationStore
  authStore: AuthStore
  localStorage: LocalStorageStore
  sessionStorage: SessionStorageStore
  searchStore: SearchStore
  infrontStore: InfrontStore
  watchlistStore: WatchlistStore

  private initialized = false

  private init = () => {
    if (this.initialized)
      throw new Error(
        'We currently do not support re-initializing the stores, instead reload the page to reset everything'
      )

    this.navigationStore = new NavigationStore()

    // All requests proxied through /api, both locally and in production builds
    this.http = new Http('/api', () => this.authStore)
    this.api = new Api(this.http)
    this.infrontStore = new InfrontStore(this.api)
    this.watchlistStore = new WatchlistStore(this.api)

    this.authStore = new AuthStore(this.api, this.infrontStore, this.watchlistStore, this.navigationStore)

    this.localStorage = new LocalStorageStore(this.authStore)
    this.sessionStorage = new SessionStorageStore(this.authStore)
    this.searchStore = new SearchStore(this.navigationStore)

    this.initialized = true
  }
}
