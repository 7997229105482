import { useLocationQuery } from '@common/hooks/useLocationQuery'
import { useEffect } from 'react'

export const useIsTesting = () => {
  const isTesting = useLocationQuery().isTesting

  useEffect(() => {
    if (isTesting) {
      window.sessionStorage.setItem('KYC-MODAL-CLOSED', 'true')
      document.cookie =
        'CookieScriptConsent=%7B%22action%22%3A%22accept%22%2C%22categories%22%3A%22%5B%5C%22functionality%5C%22%5D%22%7D'
    }
  }, [isTesting])
}
