import { TFunction } from 'i18next'

export function getOwnerDescription(owners: string[], policyHolder: string, t: TFunction) {
  let parts = []

  if (owners) {
    parts = [...owners]
  }

  if (policyHolder) {
    parts = [...parts, policyHolder]
  }

  if (parts.length > 2) {
    return [parts[0], parts[1]].join(' / ') + ' +' + (parts.length - 2) + ' ' + t('PLUS_MORE')
  }

  return parts.join(' / ')
}
