import { colors } from '@carnegie/duplo'
import { Body2Style } from '@common/components/Styles'
import { rgbAlpha } from '@common/utils/colorUtils'
import { Global, css } from '@emotion/react'

export const GlobalStylePortal = ({ insideAppWebview }: { insideAppWebview?: boolean }) => {
  return (
    <Global
      styles={css`
        ${insideAppWebview === true &&
        `body {
          background: #fafbfb !important; // pageBackground in apps colors.ts
        }`}

        // Show cookie scripts banner (we hide it index.html because it shows up before redirecting for login)
        #cookiescript_injected_wrapper#cookiescript_injected_wrapper {
          visibility: visible !important;
        }

        .MuiTabs-indicator {
          height: 2px !important;
          padding: 0px !important;
          min-height: 2px !important;
        }

        textarea:focus {
          box-shadow: none;
          outline: none;
        }

        /* 
          Q: Where are the rest of the styles? 
          A: They are in the global styles of duplo 
        */

        .highcharts-grid {
          display: none;
        }

        .highlight-astral-text {
          background-color: ${rgbAlpha(colors.goldenDream, 0.32)};
          color: ${colors.astral};
        }

        .highlight-bunker-text {
          background-color: ${rgbAlpha(colors.goldenDream, 0.32)};
          color: ${colors.bunker[200]};
        }

        /* INFRONT */

        /* Infront popups need to be in front of drawer (Mui drawers use something like 1200) */
        .cell-super-absolute {
          z-index: 2000;
        }

        /* Hides to loading bars showin in for example the chart widget */
        .cell-preloader {
          visibility: hidden !important;
        }

        /* Override for infront tooltip style */
        .cell-tooltip {
          display: block;
          padding: 8px !important;
          background-color: ${colors.offWhite}!important;
          height: 80px !important;
          width: 200px !important;
          border: 0.5px solid ${colors.bunker[20]};
          box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12),
            0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
          ${Body2Style};
          position: absolute;
          top: 0;
          right: 0;

          :before {
            display: none;
          }

          :after {
            display: none;
          }

          .cell-5-margin-top {
            margin-top: 8px !important;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
          }
          .cell-mininav__dd__func--confirm {
            flex: 1;
            float: none;
            background-color: ${colors.regentStreetBlue.main};
            border-radius: 4px;
            padding: 4px;
            margin-right: 8px;

            font-family: Roboto;
            font-size: 15px;
            line-height: 24px;
            font-weight: 400;

            &:hover {
              background-color: ${rgbAlpha(colors.regentStreetBlue[700], 1)};
              transition: background-color 0.2s ease-in-out;
            }
          }
          .cell-mininav__dd__func--cancel {
            flex: 1;
            float: none;
            background-color: ${colors.offWhite};
            border-radius: 4px;
            padding: 4px;
            position: relative;

            font-family: Roboto;
            font-size: 15px;
            line-height: 24px;
            font-weight: 400;

            &:hover {
              background-color: ${rgbAlpha(colors.regentStreetBlue.main, 0.16)};
              transition: background-color 0.2s ease-in-out;
            }

            :after {
              content: '';
              position: absolute;
              top: 0px;
              left: 0px;
              bottom: 0px;
              right: 0px;
              border: 1px solid ${colors.carnegieDarkBlue};
              border-radius: 4px;
            }
          }

          .cell-loginbox {
            background: yellow !important;
            display: none !important;
          }
        }
      `}
    />
  )
}
