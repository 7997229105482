import { pboSharedConfig } from '@carnegie/digital-channels-frontend'
import '@carnegie/duplo/lib/duplo.css'
import { useInfrontSDK } from '@common/hooks/infront/sdk/useInfrontSdk'
import { ResizeObserver } from '@juggle/resize-observer'
import { configure } from 'mobx'
import { createRoot } from 'react-dom/client'
import { version } from '../package.json'
import App from './App'
import { initApplicationInsights } from './appInsights'
import './i18n'
import { useMessagesService } from './pages/messages/useMessagesService'

console.log(`Loading PBO version ${version}`)

configure({
  enforceActions: 'never',
})

// Configure pbo-shared compatibility layer
pboSharedConfig.useInfrontSDK = useInfrontSDK
pboSharedConfig.useMessagesService = useMessagesService

// If we get to this point we should have all we need to run the app, so remove the global error handler.
// It could be triggered accidentally if we don't catch all errors in an error boundary, so better to be safe.
window.onerror = null

// Polyfill ResizeObserver for older Safari browsers.
window.ResizeObserver = window.ResizeObserver || ResizeObserver

initApplicationInsights()

const root = createRoot(document.getElementById('app'))
root.render(<App />)
