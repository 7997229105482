import { Breakpoint, useBreakpoint } from '@carnegie/duplo'
import { ReactNode } from 'react'

type ShowAtBreakpointProps = {
  greaterThanOrEqual?: Breakpoint
  lessThanOrEqual?: Breakpoint
  at?: Breakpoint | Breakpoint[]
  children?: ReactNode
}

export const ShowAtBreakpoint = ({ children, greaterThanOrEqual, at, lessThanOrEqual }: ShowAtBreakpointProps) => {
  const currentBreakpoint = useBreakpoint()
  let match = false

  if (greaterThanOrEqual !== undefined) {
    match = currentBreakpoint >= greaterThanOrEqual
  } else if (lessThanOrEqual !== undefined) {
    match = currentBreakpoint <= lessThanOrEqual
  } else if (at !== undefined) {
    if (Array.isArray(at)) {
      match = at.includes(currentBreakpoint)
    } else {
      match = currentBreakpoint === at
    }
  }

  return match === true ? <>{children}</> : null
}
